<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title>
            Fiscal Years
            <v-spacer></v-spacer>
            <v-btn fab small color="primary" @click="createItem()" v-if="canEditSettings">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-list shaped>
            <v-list-item-group color="primary">
              <v-list-item v-for="(fiscalYear, i) in fiscalYears" :key="i" @click="editItem(fiscalYear)">
                <v-list-item-icon>
                  <v-icon :color="colors[fiscalYear.color].color">mdi-checkbox-blank-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ fiscalYear.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <div class="d-flex justify-space-between mb-2" v-if="showable">
          <v-btn
            @click="
              editedItem = {};
              showable = false;
            "
          >
            Cancel
          </v-btn>
          <div>
            <v-btn
              class="mx-2"
              dark
              color="success"
              @click="saveItem()"
              v-show="!editedItem.locked && editedItem.name"
              v-if="canEditSettings"
            >
              Save
            </v-btn>
            <v-btn
              class="mx-2"
              dark
              color="error"
              @click="deleteItem()"
              v-show="!editedItem.locked && editedItem.id"
              v-if="!fiscalYearHasTrips && canEditSettings"
            >
              Delete
            </v-btn>
          </div>
        </div>
        <v-card v-show="showable" class="mb-4">
          <v-form :readonly="!canEditSettings">
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="editedItem.name" label="Fiscal Year Name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <date-picker
                      label="Starting Date"
                      v-model="editedItem.fromDate"
                      required
                      :readonly="!canEditSettings"
                    ></date-picker>
                  </v-col>
                  <v-col cols="12" md="4">
                    <date-picker
                      label="Ending Date"
                      v-model="editedItem.toDate"
                      required
                      :readonly="!canEditSettings"
                    ></date-picker>
                  </v-col>
                  <v-col cols="12" md="4" v-if="editedItem.id">
                    <v-select
                      v-model="editedItem.color"
                      :items="colors"
                      item-text="label"
                      item-value="index"
                      label="Colors"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_FISCAL_YEARS, SAVE_FISCAL_YEAR, DELETE_FISCAL_YEAR } from '@/store/modules/FiscalYear/actions';
import DatePicker from '@/components/DatePicker.vue';

export default {
  name: 'FiscalYears',
  inject: ['eventHub'],
  components: { DatePicker },
  data() {
    return {
      showable: false,
      editedItem: {},
      fiscalYears: [],
      years: [],
      colors: [
        { index: 0, label: 'Pink', color: 'pink' },
        { index: 1, label: 'Green', color: 'green' },
        { index: 2, label: 'Blue', color: 'blue' },
        { index: 3, label: 'Purple', color: 'deep-purple' },
        { index: 4, label: 'Orange', color: 'orange' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
    ...mapGetters('tripRequest', ['tripRequests']),
    fiscalYearHasTrips() {
      return this.tripRequests.some((e) => e.fiscalYearId == this.editedItem.id);
    },
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('fiscalYear', [GET_FISCAL_YEARS, SAVE_FISCAL_YEAR, DELETE_FISCAL_YEAR]),
    async fetchItems() {
      this.fiscalYears = await this.getFiscalYears();

      let startyear = new Date().getFullYear() - 5;
      if (startyear > this.fiscalYears[0].schoolYear) startyear = this.fiscalYears[0].schoolYear;
      const endyear = new Date().getFullYear() + 5;
      for (let year = startyear; year <= endyear; year++) this.years.push({ id: year, label: `${year} ~ ${year + 1}` });

      this.colorItems;
    },
    createItem() {
      this.editedItem = {};
      this.showable = true;
    },
    editItem(item) {
      this.showable = true;
      this.editedItem = item;
    },
    async saveItem() {
      try {
        const r = await this.saveFiscalYear(this.editedItem);
        if (r && r.id) {
          this.$myalert.success('Fiscal Year saved');
          await this.fetchItems();
          this.editItem(this.fiscalYears.find((e) => e.id == r.id));
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItem() {
      if (this.editedItem.id) {
        const ok = await this.$myconfirm('Are you sure you want to delete this Fiscal Year?');
        if (ok) {
          try {
            const r = await this.deleteFiscalYear(this.editedItem.id);
            if (r && r.done) {
              this.$myalert.success('Fiscal Year deleted');
              await this.fetchItems();
            }
          } catch (e) {
            this.$myalert.error(e.message);
          }
        }
      }
      this.editedItem = {};
      this.showable = false;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
